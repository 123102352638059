import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { SceneryPart } from './SceneryPart'
import { Cloud } from './Cloud'

const leafWind = keyframes(`
  20% {
    transform: rotate(3deg);
  }
  40% {
    transform: rotate(-3deg);
  }
  60% {
    transform: rotate(1deg);
  }
  80% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
  }
`)

const birds = keyframes(`
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-400px);
  }
`)

const tree = keyframes(`
  20% {
    transform: rotate(0.5deg);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-0.5deg);
  }
  80% {
    transform: rotate(0deg);
  }
`)

const cloudAnimation = keyframes(`
  0% {
    transform: translateX(-500px)
  }
  100% {
    transform: translate(100vw, 130px)
  }
`)

const cloudAnimation2 = keyframes(`
  0% {
    left: -500px;
    width: 10px;
  }
  50% {
    width: 300px;
    height: 180px;
  }
  100% {
    left: 100%;
  }
`)

const fullPageEffect = css({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'visible',
})

export const Styled = {
  Wrapper: styled.div({
    '--sun-top': '60vh',
    '--sun-left': '30%',
    '--sun-size': '3.125vw',
  
    transformStyle: 'preserve-3d',
    position: 'relative',
    height: '100vh'
  }),
  Base: styled.svg(fullPageEffect, {
    background: `radial-gradient(
      farthest-corner at 30% var(--sun-top),
        #ff648f,
        transparent 70%
      ),
      linear-gradient(to bottom, #4a51a1, #121c47)`,
    position: 'fixed',
  }),
  FogLayer: styled.div(fullPageEffect, {
    background: `100% 150%
      radial-gradient(
        farthest-corner at calc(var(--sun-left) + calc(var(--sun-size) / 2))
        calc(var(--sun-top) + var(--sun-size)),
        #ffc7a9fa,
        #ffc7a955 30%,
        transparent 100%
      )`,
    position: 'fixed',
  }),
  Stars: styled.canvas(fullPageEffect, {
    pointerEvents: 'none',
  }),
  Cloud: styled.div({
    '--offsetY': '400px',
    
    width: '60%',
    height: 105,
    borderRadius: '50%',
    top: 'calc(var(--sun-top) - var(--offsetY) + 40px)',
    filter: 'url(#filter-front)',
    boxShadow: '0 var(--offsetY) 80px 0 #ffffff80',
    left: 0,
    position: 'fixed',
  }),
  Cloud1: styled(Cloud)({
    top: 0,
    left: 500,
  }),
  Cloud2: styled(Cloud)({
    top: '40%',
    right: -40,
  }),
  Sun: styled.div({
    width: 'var(--sun-size)',
    height: 'var(--sun-size)',
    background: 'linear-gradient(to bottom, #fffa, #fff0)',
    position: 'fixed',
    left: 'var(--sun-left)',
    top: 'var(--sun-top)',
    borderRadius: '100%',
  }),
  Leaf: styled(SceneryPart)({
    animation: `${leafWind} infinite ease-in-out 2s`,
    filter: 'blur(8px)',
    transformOrigin: 'right center',
    willChange: 'auto',
    pointerEvents: 'none',
  }),
  Leaf2: styled(SceneryPart)({
    animation: `${leafWind} infinite ease-in-out 2s 0.53s`,
    filter: 'blur(8px)',
    transformOrigin: 'right center',
    willChange: 'auto',
    pointerEvents: 'none',
  }),
  Leaf3: styled(SceneryPart)({
    animation: `${leafWind} infinite ease-in-out 2s 0.23s`,
    filter: 'blur(8px)',
    transformOrigin: 'left bottom',
    willChange: 'auto',
    pointerEvents: 'none',
  }),
  Birds: styled.svg({
    position: 'fixed',
    top: '60%',
    left: 0,
    overflow: 'visible',
    filter: 'blur(1px)',
    animation: `${birds} 60s linear infinite`,
    width: '2%',
    willChange: 'auto'
  }),
  Fireflies: styled.canvas(fullPageEffect, {
    zIndex: 2,
    position: 'absolute',
    pointerEvents: 'none',
  }),
}
