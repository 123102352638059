import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { PERSPECTIVE_BASE } from './ParallaxWrapper'

interface ParallaxProps {
  children: ReactNode
  distance: number
  isSticky?: boolean
}

const StyledParallax = styled.div<{ distance: number, isSticky?: boolean }>(({ distance, isSticky }) => ({
  position: isSticky ? 'sticky' : 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transform: `translateZ(${distance}px) scale(${(PERSPECTIVE_BASE - distance) / PERSPECTIVE_BASE})`,
}))

export const Parallax = ({ children, distance, isSticky }: ParallaxProps): JSX.Element => (
  <StyledParallax distance={distance} isSticky={isSticky}>
    {children}
  </StyledParallax>
)
