import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import { css } from '@emotion/react'

interface CloudProps {
  id: string
  height: number
  width: number
  seed?: number
  baseFrequency?: number
}

const getCloudLayer = (layer: 'front' | 'mid' | 'back', width: number, height: number,  id: string) => {
  let boxShadow: string
  
  switch (layer) {
    case 'back':
      boxShadow = `0 ${40 + height}px ${height / 10}px -${height / 15}px #b97594`
      break
    case 'mid':
      boxShadow = `0 ${40 + height + ((height / 100) * 13.3)}px ${(height / 10) * 2}px -${height / 5}px #ffc7a980`
      break
    case 'front':
      boxShadow = `0 ${40 + height + ((height / 100) * 23.3)}px ${(height / 10) * 3}px -${height / 3}px rgba(219, 127, 111, .3)`
      break
  }
  
  return css({
    filter: `url(#filter-${layer}-${id})`,
    transform: `translateY(-${height}px)`,
    position: 'absolute',
    boxShadow,
    opacity: .2,
    height,
    width,
  })
}

const Styled = {
  Back: styled.div<CloudProps>(({ id, width, height }) => getCloudLayer('back', width, height, id)),
  Mid: styled.div<CloudProps>(({ id, width, height }) =>  getCloudLayer('mid', width, height, id)),
  Front: styled.div<CloudProps>(({ id, width, height }) =>  getCloudLayer('front', width, height, id)),
}

export const Cloud = ({ id, height, width, baseFrequency = 0.012, seed = 0,  ...rest }: CloudProps & HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id={`filter-back-${id}`}>
          <feTurbulence type="fractalNoise" baseFrequency={baseFrequency} numOctaves="4" seed={seed} />
          <feDisplacementMap  in="SourceGraphic" scale="170" />
        </filter>
        <filter id={`filter-mid-${id}`}>
          <feTurbulence type="fractalNoise"  baseFrequency={baseFrequency} numOctaves="2" seed={seed}/>
          <feDisplacementMap  in="SourceGraphic" scale="150" />
        </filter>
        <filter id={`filter-front-${id}`}>
          <feTurbulence type="fractalNoise" baseFrequency={baseFrequency} numOctaves="2" seed={seed}/>
          <feDisplacementMap  in="SourceGraphic" scale="100" />
        </filter>
      </defs>
    </svg>
    <Styled.Back id={id} height={height} width={width} {...rest} />
    <Styled.Mid id={id} height={height} width={width} {...rest} />
    <Styled.Front id={id} height={height} width={width} {...rest} />
  </>
)
