import styled from '@emotion/styled'

interface SceneryPartProps {
  id: string
}

const StyledSceneryPart = styled.svg({
  position: 'absolute',
  bottom: -1,
  width: '100%',
  pointerEvents: 'none',
})

export const SceneryPart = ({ id, ...rest }: SceneryPartProps) => {
  return (
    <StyledSceneryPart viewBox="0 0 1280 670" preserveAspectRatio="xMidYMax meet" data-id={id} {...rest}>
      <use href={`#${id}`}/>
    </StyledSceneryPart>
  )
}
