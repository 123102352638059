import { SceneryAssets } from './SceneryAssets'
import { Styled } from './Scenery.style'
import Image from 'next/image'
import { Bird } from './Bird'
import { Parallax } from '../parallax/Parallax'
import { useStars } from '../../hooks/useStars'
import { SceneryPart } from './SceneryPart'
import { useFireflies } from '../../hooks/useFireflies'

const Scenery = () => {
  const { starsRef } = useStars()
  const { ref: firefliesRef } = useFireflies()
  
  return (
    <Styled.Wrapper>
      <Image src="/transparent_pixel.png" alt="test test test" width="1" height="1"/>
      <SceneryAssets />
      <Parallax distance={-100}>
        <Styled.Base />
        <Styled.FogLayer />
        <Styled.Sun />
        <Styled.Birds viewBox="0 0 60 60" preserveAspectRatio="xMidYMax meet">
          <Bird />
          <Bird position={{ x: 20, y: 20 }} delay={0.2} size={14} />
          <Bird position={{ x: 70, y: 31 }} delay={0.25} size={13} />
          <Bird position={{ x: 14, y: 52 }} delay={0.32} />
          <Bird position={{ x: 120, y: 44 }} delay={0.4} />
          <Bird position={{ x: 123, y: 2 }} delay={0.5} />
          <Bird position={{ x: 242, y: 55 }} delay={0.1} />
          <Bird position={{ x: 188, y: 23 }} delay={0.1} size={13} />
          <Bird position={{ x: 176, y: 89 }} delay={0.3} />
          <Bird position={{ x: 33, y: 112 }} delay={0.4} />
        </Styled.Birds>
        <Styled.Stars ref={starsRef} />
        <Styled.Fireflies ref={firefliesRef}/>
        <Styled.Cloud1 id="cloud1" width={730} height={300} />
        <Styled.Cloud2 id="cloud2" width={500} height={180} seed={0.2} />
      </Parallax>
      <Parallax distance={-95}>
        <SceneryPart id="mountain-3" />
        <SceneryPart id="mountain-2" />
        <SceneryPart id="mountain-1" />
      </Parallax>
      <Parallax distance={-70}>
        <SceneryPart id="mountain-with-pine-forrest" />
        <Styled.Cloud />
      </Parallax>
      <Parallax distance={-50}>
        <SceneryPart id="mountain-right-dark" />
        <SceneryPart id="mountain-left-dark" />
      </Parallax>
      <Parallax distance={-20}>
        <SceneryPart id="pine-forrest-left" />
        <SceneryPart id="pine-forrest-right" />
      </Parallax>
      <Parallax distance={0}>
        <SceneryPart id="grass-right"/>
        <SceneryPart id="grass-left"/>
        <SceneryPart id="tree"/>
        <SceneryPart id="edge"/>
      </Parallax>
      <Parallax distance={60}>
        <Styled.Leaf id="leaf2" />
        <Styled.Leaf2 id="leaf1" />
        <Styled.Leaf3 id="flower" />
      </Parallax>
    </Styled.Wrapper>
  )
}

export default Scenery
