import { useEffect, useMemo, useRef } from 'react'

class Star {
  x: number
  y: number
  size: number
  
  static randomRange(min: number, max: number) {
    return Math.random() * (max - min) + min
  }
  
  constructor() {
    this.x = Star.randomRange(0, window.innerWidth)
    this.y = Star.randomRange(0, window.innerHeight)
    this.size = Star.randomRange(0.1, 2)
  }
}

export const useStars = () => {
  const starsRef = useRef<HTMLCanvasElement>(null)
  
  const fitToScreen = (element: HTMLCanvasElement): void => {
    element.width = window.innerWidth
    element.height = window.innerHeight
  }
  
  const clearScreen = (canvas: HTMLCanvasElement): void => {
    const ctx = canvas.getContext('2d')!
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }
  
  const drawStars = (flies: Star[], ctx: CanvasRenderingContext2D) => {
    const stars = new Array(50).fill(undefined).map(() => new Star())
    stars.forEach(star => {
      ctx.fillStyle = 'rgba(255, 255, 255, .1)'
      ctx.beginPath()
      ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2)
      ctx.fill()
    })
  }
  
  useEffect(() => {
    const canvas = starsRef.current!
    const stars = new Array(100).fill(undefined).map(() => new Star())
    const render = () => {
      fitToScreen(canvas)
      clearScreen(canvas)
      drawStars(stars, canvas.getContext('2d')!)
    }
    
    render()
    
    window.addEventListener('resize', render);
  }, [])
  
  return { starsRef }
}
