import { useEffect, useRef } from 'react'

const FLY_X_SPEED_RANGE = [-1, 1]
const FLY_Y_SPEED_RANGE = [-0.5, 0.5]
const FLY_SIZE_RANGE = [1, 3]
const FLY_LIFESPAN_RANGE = [75, 150]

interface IFly {
  x: number
  y: number
  xSpeed: number
  ySpeed: number
  size: number
  lifespan: number
  age: number
  colors: { red: number, green: number, blue: number, alpha: number }
}

class Fly implements IFly {
  x: number
  y: number
  xSpeed: number
  ySpeed: number
  size: number
  lifespan: number
  age: number
  colors: { red: number, green: number, blue: number, alpha: number }
  
  static randomRange(min: number, max: number) {
    return Math.random() * (max - min) + min
  }
  
  constructor(options: Fly = {
    x: Fly.randomRange(0, window.innerWidth),
    y: Fly.randomRange(0, window.innerHeight),
    xSpeed: Fly.randomRange(FLY_X_SPEED_RANGE[0], FLY_X_SPEED_RANGE[1]),
    ySpeed: Fly.randomRange(FLY_Y_SPEED_RANGE[0], FLY_Y_SPEED_RANGE[1]),
    size: Fly.randomRange(FLY_SIZE_RANGE[0], FLY_SIZE_RANGE[1]),
    lifespan: Fly.randomRange(FLY_LIFESPAN_RANGE[0], FLY_LIFESPAN_RANGE[1]),
    age: 0,
    colors: {
      red: 217,
      green: 73,
      blue: 29,
      alpha: 0
    }
  }) {
    this.x = options.x
    this.y = options.y
    this.xSpeed = options.xSpeed
    this.ySpeed = options.ySpeed
    this.size = options.size
    this.lifespan = options.lifespan
    this.age = options.age
    this.colors = options.colors
  }
}

export const useFireflies = () => {
  const ref = useRef<HTMLCanvasElement>(null)
  const FIREFLY_COUNT = 30
  
  const fitToScreen = (element: HTMLCanvasElement): void => {
    element.width = window.innerWidth
    element.height = window.innerHeight
  }
  
  const clearScreen = (canvas: HTMLCanvasElement): void => {
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }
  }
  
  const createFlies = (flies: Fly[]) => {
    if (flies.length !== FIREFLY_COUNT) {
      flies.push(new Fly())
    }
  }
  
  const moveFlies = (flies: Fly[]) => {
    flies.forEach(fly => {
      fly.x += fly.xSpeed
      fly.y += fly.ySpeed
      fly.age++
      
      if (fly.age < fly.lifespan / 2) {
        fly.colors.alpha += 1 / (fly.lifespan / 2)
        
        if (fly.colors.alpha > 1) { fly.colors.alpha = 1 }
      } else {
        fly.colors.alpha -= 1 / (fly.lifespan / 2)
        
        if (fly.colors.alpha < 0) { fly.colors.alpha = 0 }
      }
    })
  }
  
  const removeFlies = (flies: Fly[]) => {
    let i = flies.length
    
    while (i--) {
      const fly = flies[i]
      
      if (fly.age >= fly.lifespan) {
        flies.splice(i, 1)
      }
    }
  }
  
  const drawFlies = (flies: Fly[], ctx: CanvasRenderingContext2D) => {
    flies.forEach(fly => {
      const g = ctx.createRadialGradient(fly.x, fly.y, 0, fly.x, fly.y, fly.size)
      g.addColorStop(0.0, `rgba(238, 180, 28, ${fly.colors.alpha})`)
      g.addColorStop(1.0, 'rgba(238, 180, 28, 0)')
      ctx.fillStyle = g
      
      ctx.beginPath()
      ctx.arc(
        fly.x,
        fly.y,
        fly.size,
        0,
        Math.PI * 2,
        false
      )
      ctx.fill()
    })
  }
  
  useEffect(() => {
    const { current: canvas } = ref
    
    if (!canvas) {
      return
    }
    
    const ctx = canvas.getContext('2d')
    const flies: Fly[] = []
    
    if (!ctx) return
    
    window.addEventListener('resize', () => {
      fitToScreen(canvas)
    })
    
    fitToScreen(canvas)
    
    const render = () => {
      clearScreen(canvas)
      createFlies(flies)
      moveFlies(flies)
      removeFlies(flies)
      drawFlies(flies, ctx)
    }
    
    (function animationLoop() {
      window.requestAnimationFrame(animationLoop)
      render()
    })()
  }, [])
  
  return { ref }
}

