import styled from '@emotion/styled'

interface BirdProps {
  position?: { x: number, y: number }
  delay?: number
  size?: number
}

const StyledBird = styled.path<BirdProps>(({ position = { x: 0, y: 0 }, size = 15 }: BirdProps) => ({
  transform: `translate(${position.x}px, ${position.y}px) scale(.3)`,
  fill: 'rgb(39, 72, 98)',
}))

export const Bird = ({ delay, ...rest }: BirdProps) => (
  <StyledBird {...rest}>
    <animate
      attributeName="d"
      dur="350ms"
      begin={`${delay}s`}
      repeatCount="indefinite"
      keySplines="0,0,1,1;.42,0,.58,1;.42,0,1,1;0,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1"
      keyTimes="0;.0625;.208333333;.3125;.395833333;.645833333;.833333333;1"
      calcMode="spline"
      values="M35.471,25.299C35.471,25.299 28.494,23.053 25.355,23.258C22.205,23.458 10.763,25.089 8.222,26.603L16.969,31.866C16.969,31.866 7.584,49.515 10.79,58.283C10.79,58.283 31.711,48.405 32.499,36.248L37.13,34.606L44.623,36.819L49.914,37.379L49.709,34.251L38.411,30.047L35.471,25.299Z;
        M24.365,24.783C17.46,25.504 8.569,25.798 8.569,25.798L8.292,26.652L17.787,30.567C17.787,30.567 21.908,43.269 23.322,44.589C24.725,45.909 29.124,52.675 29.124,52.675C29.124,52.675 38.364,36.84 37.56,34.65C36.75,32.442 51.708,33.74 51.708,33.74C51.708,33.74 31.253,24.045 24.365,24.783Z;
        M9.492,25.678L8.222,26.61C8.222,26.61 17.146,31.674 18.594,33.549C20.03,35.434 25.006,51.186 30.369,52.262C35.732,53.321 36.742,38.302 36.742,38.302L37.346,37.42L40.652,37.487L46.758,39.91L51.561,37.348C51.561,37.348 23.131,21.973 9.492,25.678Z;
        M35.2,29.396C35.055,27.161 39.304,19.713 36.348,14.521C33.38,9.335 31.295,9.463 31.295,9.463L27.884,5.647C27.884,5.647 23.025,11.787 23.103,13.123C23.197,14.477 19.476,21.887 20.202,24.976C20.918,28.071 8.222,26.645 8.222,26.645L15.399,32.02C15.399,32.02 12.504,44.316 13.636,45.586C13.636,45.586 29.226,42.791 35.66,36.546L45.233,38.165L50.058,39.652L49.626,32.963C49.626,32.963 35.338,31.626 35.2,29.396Z;
        M16.941,38.709C16.941,38.709 27.18,38.62 32.327,36.59C37.49,34.566 40.53,35.509 40.53,35.509L47.285,37.888L49.548,31.815L39.254,31.066L36.093,28.171C36.093,28.171 37.002,11.471 25.61,9.091C25.61,9.091 19.01,24.754 19.065,25.597C19.115,26.446 8.222,26.579 8.222,26.579L9.37,29.042L17.252,33.329L16.941,38.709Z;
        M40.868,35.712C47.607,34.991 49.171,35.534 49.426,36.011L50.125,32.428L37.867,30.077C37.867,30.077 36.492,24.774 38.028,24.236C39.565,23.687 42.926,10.459 45.51,9.844C48.1,9.234 24.922,8.479 20.646,23.554L18.633,25.03L8.222,26.594C8.222,26.594 11.999,28.147 13.658,29.383C15.316,30.626 30.424,36.838 40.868,35.712Z;
        M48.699,34.017L44.368,32.054L36.226,28.094C36.226,28.094 42.033,12.026 33.591,3.596L28.677,8.837C28.677,8.837 19.975,20.612 21.217,23.679C22.471,26.729 8.222,25.864 8.222,25.864L8.311,27.201C8.311,27.201 15.372,30.773 15.46,32.12C15.543,33.457 15.693,35.698 15.693,35.698C15.693,35.698 32.992,35.027 34.562,34.921C36.131,34.827 44.678,36.968 44.678,36.968L48.699,34.017Z;
        M37.784,28.171C45.166,12.004 34.989,1 34.989,1C34.989,1 31.328,9.303 29.26,9.885C27.191,10.473 20.236,24.816 20.236,24.816L8.222,26.036C10.596,30.379 29.908,36.308 29.908,36.308C29.908,36.308 39.326,35.703 41.95,35.531C44.567,35.365 49.032,39.564 49.032,39.564L51.001,37.201L51.778,33.108L37.923,30.407L37.784,28.171Z;
      "/>
  </StyledBird>
)
